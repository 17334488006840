import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Media, NavLink } from 'reactstrap';

class ProductCarousel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {items, brand, slidesToShow, className} = this.props;
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      // infinite: false,
      // autoplay: false,
      lazyLoad: true,
      speed: 500,
      slidesToShow: slidesToShow || (items.length > 3 ? 4 : items.length),
      slidesToScroll: 1,
      pauseOnHover: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: slidesToShow || (items.length > 2 ? 3 : items.length),
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: slidesToShow || (items.length > 2 ? 2 : items.length),
          },
        },
      ],
    };
    // debugger

    return <>
      <Slider {...settings} className={`${className}`}>
        { items && items.map(item => (
          // <NavLink href={brand && `${brand}/${item.slug}`} key={item} className="px-0 py-0">
          <NavLink href={brand && `${item.image_url}`} key={item} className="px-0 py-0">
            <Media object src={item.image_url || item.sm || ''} alt={item.slug} className="w-100" width="100"  />
          </NavLink>
        )) }

        {/*
        <div class="slick-slider my-4 slick-initialized" dir="ltr">
          <button class="slick-arrow slick-prev">
          <div class="slick-list">
            <div class="slick-track" style="width: 4448px; opacity: 1; transform: translate3d(-1668px, 0px, 0px); transition: -webkit-transform 500ms;">
              <div data-index="-4" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style="width: 278px;">
                <div>
                  <a href="benelli/undefined" tabindex="-1" class="px-0 py-0 nav-link" style="width: 100%; display: inline-block;">
                    <img src="/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWVE9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bffd800fecfab9a0f21521f26d522ee77b1d5e17/3.jpg" width="100" class="w-100 media-object">
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button class="slick-arrow slick-next">
        </div>

        /benelli/compania
        1
          Image in new tab:
            https://s3.amazonaws.com/agrobikes.com.ar/w9d44y9f2dx1o08swu8w47fwnohe?response-content-disposition=inline%3B%20filename%3D%221.jpg%22%3B%20filename%2A%3DUTF-8%27%271.jpg&response-content-type=image%2Fjpeg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIQMEVLQF2VBMCXMA%2F20241126%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241126T190012Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=b62576b1f17f5696a3790bfe2624202114ee1f8a4072b5f36366010ab57d6386
          item.image_url
            "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWUk9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a20c5b88baf3b970800b6cb1dada735c1b4836e0/1.jpg"
        */}
      </Slider>

      {/* 
      <style>{`
      .slick-prev:before, .slick-next:before {
        color: black;
      }
      `}</style>
      */}
    </>
  }
}
ProductCarousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  brand: PropTypes.string.isRequired,
  slidesToShow: PropTypes.number,
  className: PropTypes.string,
};

ProductCarousel.defaultProps = {
  slidesToShow: undefined,
  className: "",
}

export default ProductCarousel;
