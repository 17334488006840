import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Media, Container, Row, Col,
} from 'reactstrap';
import WOW from 'wowjs';
import createMarkup from './Utils';
import ProductCarousel from './ProductCarousel';

class Company extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }

  render() {
    const {
      description, picture, pictures, slug, stories,
    } = this.props;

    return (
      <div className=" bg-white text-dark company">
        <Media object src={picture} className="w-100" width="100" height="100" />

        <Container>
          <Media tag="h1" className="py-5 mb-0 wow fadeInUp d-block text-center">Nosotros</Media>

          <div dangerouslySetInnerHTML={createMarkup(description)} className="description-html columns px-4 pt-0 pb-4" />

          { pictures && pictures.length > 0 && 
            <ProductCarousel items={pictures} brand={slug} className={'my-4'}/>
          }

          <hr />

          { stories && stories.length > 0 && (
            <div className="pb-3">
              <Media tag="h1" className="py-5 mb-0 wow fadeInUp d-block text-center">Nuestra Historia</Media>
              { stories.map((story) => (
                <Row key={story.title} className="py-2">
                  { story.picture && (
                    <Col xs={12} md={6}>
                      <Media object src={story.picture.image_url} className="w-100 mb-3" width="100" height="100" />
                    </Col>
                  ) }
                  <Col xs={12} md={story.picture ? 6 : 12}>
                    <Media tag="h3" className="pb-3 pt-0 mb-0 wow fadeInUp d-block text-center text-md-left">{story.title}</Media>
                    <div dangerouslySetInnerHTML={createMarkup(story.description)} className="description-html px-0 pt-0 pb-4" />
                  </Col>
                </Row>
              )) }
            </div>
          )}
        </Container>
      </div>
    );
  }
}

Company.propTypes = {
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  picture: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  pictures: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  stories: PropTypes.array,
};

Company.defaultProps = {
  picture: '',
  pictures: [],
  stories: [],
};

export default Company;
